import { Survey } from "survey-react-ui";
import { useNavigate, useLocation } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "survey-core/survey.i18n";
import { useTranslation } from 'react-i18next';

// Import survey templates
import preSurveyJsonPt from "../../surveyTemplates/Pt/preSurveyFormPt";
import postSurveyFormPt from "../../surveyTemplates/Pt/postSurveyFormPt";
import preSurveyJsonHindi from "../../surveyTemplates/Hi/preSurveyFormHindi";
import postSurveyFormHindi from "../../surveyTemplates/Hi/postSurveyFormHindi";
import preSurveyJsonEn from "../../surveyTemplates/En/preSurveyFormEn";
import postSurveyFormEn from "../../surveyTemplates/En/postSurveyFormEn";
import preSurveyJsonEnx from "../../surveyTemplates/Enx/preSurveyFormEnx";
import postSurveyFormEnx from "../../surveyTemplates/Enx/postSurveyFormEnx";
import preSurveyJsonEs from "../../surveyTemplates/Es/preSurveyFormEs";
import postSurveyFormEs from "../../surveyTemplates/Es/postSurveyFormEs";
import preSurveyJsonPtx from "../../surveyTemplates/Ptx/preSurveyFormPtx";
import postSurveyFormPtx from "../../surveyTemplates/Ptx/postSurveyFormPtx";
import preSurveyJsonSp from "../../surveyTemplates/Sp/preSurveyFormSp";
import postSurveyFormSp from "../../surveyTemplates/Sp/postSurveyFormSp";

// Import the JSON files for Telugu survey
import data2 from "../../surveyTemplates/Tl/data2.json";

// Remove the old participants.json import:
// import participantData from "../../surveyTemplates/Tl/participants.json";

// Import the two new participants files
import participantsShiva from "../../surveyTemplates/Tl/participants_shiva.json";
import participantsBalaji from "../../surveyTemplates/Tl/participants_balaji.json";

const SurveyPage = () => {
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const { state } = location;
    const checked_users = state.checkedUsers;
    const unchecked_users = state.uncheckedUsers;
    const formType = state.formType;

    // We'll store whichever array we need here, based on enumerator_name
    let enumeratorData = [];

    const saveSurveyToServer = (data) => {
        console.log(`saving ${data} to server..`);
        axiosPrivate
            .post("/save-survey", data)
            .then((res) => {
                if (res.data === "SurveySaved") {
                    console.log("Form Saved to server!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // Decide which survey form to use based on formType (pre or post) and language
    let surveyJson = {};
    if (formType === 0) {
        switch (i18n.language) {
            case 'en':
                surveyJson = preSurveyJsonEn();
                break;
            case 'enx':
                surveyJson = preSurveyJsonEnx();
                break;
            case 'pt':
                surveyJson = preSurveyJsonPt();
                break;
            case 'ptx':
                surveyJson = preSurveyJsonPtx();
                break;
            case 'hi':
                surveyJson = preSurveyJsonHindi();
                break;
            case 'es':
                surveyJson = preSurveyJsonEs();
                break;
            case 'sp':
                surveyJson = preSurveyJsonSp();
                break;
            case 'tl':
                surveyJson = data2; // Use data2.json for the Telugu pre-survey
                break;
            default:
                surveyJson = preSurveyJsonEn();
                break;
        }
    } else {
        switch (i18n.language) {
            case 'en':
                surveyJson = postSurveyFormEn(unchecked_users, checked_users);
                break;
            case 'enx':
                surveyJson = postSurveyFormEnx(unchecked_users, checked_users);
                break;
            case 'pt':
                surveyJson = postSurveyFormPt(unchecked_users, checked_users);
                break;
            case 'ptx':
                surveyJson = postSurveyFormPtx(unchecked_users, checked_users);
                break;
            case 'hi':
                surveyJson = postSurveyFormHindi(unchecked_users, checked_users);
                break;
            case 'es':
                surveyJson = postSurveyFormEs(unchecked_users, checked_users);
                break;
            case 'sp':
                surveyJson = postSurveyFormSp(unchecked_users, checked_users);
                break;
            // case 'tl':
            //     surveyJson = data2; // (If needed, you can add a post-survey version for Telugu)
            //     break;
            default:
                surveyJson = postSurveyFormEn(unchecked_users, checked_users);
                break;
        }
    }

    // Create the Survey model
    const survey = new Model(surveyJson);

    if (i18n.language === 'tl') {
        // We'll dynamically set enumeratorData based on enumerator_name's selection
        // and then filter/fetch data from enumeratorData on 'interview_time' & 'participant_id'

        survey.onValueChanged.add((sender, options) => {
            // 1) If enumerator_name changes, switch the data source
            if (options.name === "enumerator_name") {
                if (options.value === 1) {
                    enumeratorData = participantsShiva;
                } else if (options.value === 2) {
                    enumeratorData = participantsBalaji;
                } else {
                    enumeratorData = []; // fallback if 'Other' or unknown
                }
            }

            // 2) If interview_time changes, filter enumeratorData for that slot
            if (options.name === "interview_time") {
                const timeSlot = options.value;
                const filteredParticipants = enumeratorData.filter(
                    (p) => p.time_slot === timeSlot
                );

                const participantQuestion = survey.getQuestionByName("participant_id");
                participantQuestion.choices = [
                    ...filteredParticipants.map((p) => ({
                        value: p.name,
                        text: p.name,
                    })),
                    {
                        value: "NA",
                        text: "NA",
                    },
                ];
            }

            // 3) If participant_id changes, set phone_number from enumeratorData
            if (options.name === "participant_id") {
                const selectedName = options.value;
                const selectedParticipant = enumeratorData.find(
                    (p) => p.name === selectedName
                );
                if (selectedParticipant) {
                    survey.setValue("phone_number", selectedParticipant.phone_number);
                }
            }
        });

        // For Markdown
        const converter = new showdown.Converter();
        survey.onTextMarkdown.add((survey, opt) => {
            if (opt.text.includes("{phone_number}")) {
                const phoneNumber = survey.getValue("phone_number");
                let text = opt.text.replace(
                    "{phone_number}",
                    phoneNumber || "___________"
                );
                let html = converter.makeHtml(text);
                // remove surrounding <p> tags
                html = html.substring(3, html.length - 4);
                opt.html = html;
            } else {
                let str = converter.makeHtml(opt.text);
                str = str.substring(3, str.length - 4);
                opt.html = str;
            }
        });
    } else {
        // Existing Markdown handler for non-Telugu
        const converter = new showdown.Converter();
        survey.onTextMarkdown.add(function (survey, options) {
            let str = converter.makeHtml(options.text);
            str = str.substring(3, str.length - 4);
            options.html = str;
        });
    }

    // On Complete: Save to server, then navigate
    survey.onComplete.add((sender, options) => {
        let surveyInfo = {
            info: {
                clientId: state.clientId,
                clientName: state.clientName,
                surveyType: state.formType,
                timestamp: new Date(),
            },
        };
        let data = { ...surveyInfo, ...sender.data };
        console.log(data);
        saveSurveyToServer(data);

        setTimeout(() => {
            if (formType === 0) {
                let stateData = {
                    username: state.data.username,
                    village: state.data.village,
                    tehsilOrBlock: state.data.tehsilOrBlock,
                    email: state.data.email,
                    waNo: state.data.waNo,
                    bio: state.data.bio,
                    userId: state.data.userId,
                };
                navigate("/addUser", { state: { from: location, data: stateData }, replace: true });
            } else {
                navigate("/allUsers", { state: { from: location }, replace: true });
            }
        }, 2000);
    });

    // Set the survey's locale
    survey.locale = i18n.language;

    return <Survey model={survey} />;
};

export default SurveyPage;
